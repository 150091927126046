import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-primary-header',
  templateUrl: './primary-header.component.html',
  styleUrls: ['./primary-header.component.scss'],
})
export class PrimaryHeaderComponent {
  @Input() canGoBack: boolean = false;
  @Input() displayLegalNotice: boolean = false;

  goBack() {
    window.history.back();
  }
}
