import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GoogleMapsApiUrl } from '@environments/google-api';

@Injectable({
  providedIn: 'root',
})
export class GoogleApiService {
    isMapsLoaded: boolean = false;
    options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
    };

    constructor() {}

    loadGoogleMaps(): Promise<void> {
      return new Promise((resolve, reject) => {
        if (this.isMapsLoaded) {
          resolve();
          return;
        }
  
        const script = document.createElement('script');
        script.src = GoogleMapsApiUrl;
        script.async = true;
        script.defer = true;
  
        script.onload = () => {
          this.isMapsLoaded = true;
          resolve();
        };
  
        script.onerror = () => {
          reject('Error while charging Google Maps API');
        };
  
        document.body.appendChild(script);
      });
    }
  
    setMapsLoaded(status: boolean) {
      this.isMapsLoaded = status;
    }

    getMapsOptions() {
      return this.options;
    }
}