import { Component, Input } from '@angular/core';
import { goBackGeneric } from '@app/utils/utils';

@Component({
  selector: 'app-white-header',
  templateUrl: './white-header.component.html',
  styleUrls: ['./white-header.component.scss'],
})
export class WhiteHeaderComponent {
  @Input() canGoBack: boolean = true;
  @Input() canLogout: boolean = false;
  @Input() canGoToProfile: boolean = true;
  @Input() loggedInUserId: number = null;
  @Input() userId: number = null;
  @Input() logoutFunction!: () => void;
  @Input() goBackFunction!: () => void;

  goBack() {
    if (this.goBackFunction) {
      goBackGeneric(this.goBackFunction)
    } else {
      goBackGeneric()
    }
  }

  callLogout() {
    if (this.logoutFunction) {
      this.logoutFunction();
    }
  }

}
