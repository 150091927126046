import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AccountType } from '../utils/onboarding/accountType';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
interface AccountInterface {
  id: number;
  country_code: string;
  phone: string;
  email: string;
  is_set: boolean;
}

export type OwnerType = Omit<AccountInterface, "id" >
export type PatientType = Omit<AccountInterface, "email">

@Injectable({
  providedIn: 'root'
})

export class OnboardingService {
  private userType = new BehaviorSubject<AccountType>(null);
  private patient = new BehaviorSubject<PatientType | null>(null);
  private owner = new BehaviorSubject<OwnerType | null>(null);
  private contacts = new BehaviorSubject<Array<object>>([]);
  private clientReferenceId = new BehaviorSubject<string>("")
  private clientSecret = new BehaviorSubject<string>("")
  formError = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
  ) {}

  getOwner() {
    return this.owner.asObservable().pipe(
      switchMap(owner => {
        if (owner) {
          return of(owner);
        } else {
          const storedOwner = JSON.parse(this.retrieveFromLocalStorage("ONBOARDING_OWNER"));
          return of(storedOwner);
        }
      }) 
    )
  }

  printLocalStorage() {
    console.info("Local Storage Data :");
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        let value;
        try {
          value = JSON.parse(localStorage.getItem(key) || 'null');
        } catch (e) {
          value = localStorage.getItem(key); // If value is not JSON, print it raw
        }
        console.info(`Key: ${key}, Value: ${JSON.stringify(value)}`);
      }
    }
  }

  printBehaviorSubjects() {
    console.info("=== Behavior Subjects Data Start ===");
    console.info(`User Type: ${this.userType.getValue()}`);
    console.info("Patient:", JSON.stringify(this.patient.getValue(), null, 2));
    console.info("Owner:", JSON.stringify(this.owner.getValue(), null, 2));
    console.info("Contacts:", JSON.stringify(this.contacts.getValue(), null, 2));
    console.info(`Client Reference ID: ${this.clientReferenceId.getValue()}`);
    console.info(`Client Secret: ${this.clientSecret.getValue()}`);
    console.info("Form Error:", JSON.stringify(this.formError.getValue(), null, 2));
    console.info("=== Behavior Subjects Data End ===");
  }

  setOwner(updatedFields) {
    const currentOwner = this.owner.getValue();
    const newOwner = { 
        ...currentOwner, 
        ...updatedFields 
    };
    this.owner = new BehaviorSubject(newOwner); 
    const { country_code, phone, email, is_set } = newOwner;
    const savedOwner = {
        "country_code": country_code,
        "phone": phone,
        "email": email,
        "is_set": is_set
    };
    this.saveInLocalStorage("ONBOARDING_OWNER", JSON.stringify(savedOwner));
}

  getPatient() {
    return this.patient.asObservable().pipe(
      switchMap(patient => {
        if (patient) {
          return of(patient);
        } else {
          const storedPatient = JSON.parse(this.retrieveFromLocalStorage("ONBOARDING_PATIENT"));
          return of(storedPatient);
        }
      }) 
    )
  }

  setPatient(updatedFields) {
    const currentPatient = this.patient.getValue();
    const newPatient = { 
        ...currentPatient, 
        ...updatedFields 
    };
    this.patient = new BehaviorSubject(newPatient); 
    const { id, country_code, phone, is_set } = newPatient;
    const savedPatient = {
        "id": id,
        "country_code": country_code,
        "phone": phone, 
        "is_set": is_set
    };
    this.saveInLocalStorage("ONBOARDING_PATIENT", JSON.stringify(savedPatient));
  }

  setUser(userType: AccountType, updatedFields) {
    if (userType !== 'OWNER' && userType !== 'PATIENT') {
      throw new Error('Invalid userType provided');
    }
    if (userType === 'OWNER') {
      this.setOwner(updatedFields);
    } else {
      this.setPatient(updatedFields);
    }
  }

  getUser(userType: AccountType) {
    if (userType !== 'OWNER' && userType !== 'PATIENT') {
      throw new Error('Invalid userType provided');
    }
    if (userType === 'OWNER') {
      return this.getOwner();
    } else {
      return this.getPatient();
    }
  }

  getContacts() {
    return this.contacts.asObservable().pipe(
      switchMap(contacts => {
        if (contacts.length > 0) {
          return of(contacts);
        } else {
          const storedContacts = JSON.parse(this.retrieveFromLocalStorage("ONBOARDING_CONTACTS"));
          return of(storedContacts || []);
        }
      }) 
    )
  }

  addContacts(contacts) {
    const currentContacts = this.contacts.value || [];
    const { country_code, phone, email, firstName, lastName, relative, invitationState } = contacts
    const savedContact = {
      country_code, phone, email, firstName, lastName, relative, invitationState
    }
    const updatedContacts = [...currentContacts, savedContact]
    this.saveInLocalStorage("ONBOARDING_CONTACTS", JSON.stringify(updatedContacts))
    this.contacts.next(updatedContacts);
  }

  sendConfirmationCode(countryCode, phone): Observable<any> {
    const data = {
      country_code: countryCode,
      phone: phone
    };
    return this.http.post(`${environment.apiUrl}/user/sendCode/`, data);
  }

  verifyConfirmationCode(countryCode, phone, confirmationCode): Observable<any> {
    const data = {
      country_code: countryCode,
      phone: phone,
      confirmationCode: confirmationCode
    };
    return this.http.post(`${environment.apiUrl}/user/verifyCode/`, data);
  }

  checkPhoneExists(countryCode, phone): Observable<any> {
      return this.http.get(`${environment.apiUrl}/user/phoneExist/${countryCode}/${phone}/`);
  }

  checkEmailExists(email): Observable<any> {
    return this.http.get(`${environment.apiUrl}/user/emailExist/${email}/`);
  }

  updateContacts(contacts) {
    this.saveInLocalStorage("ONBOARDING_CONTACTS", JSON.stringify(contacts))
    this.contacts.next(contacts);
  }

  getUserType() {
    return this.userType.asObservable().pipe(
      switchMap(userType => {
        if (userType) {
          return of(userType);
        } else {
          const storedUserType = this.retrieveFromLocalStorage("ONBOARDING_USERTYPE");
          return of(storedUserType);
        }
      }) 
    )
  }

  getUserToConfigure(): Observable<AccountType> {
    return this.getUserType().pipe(
      switchMap((userType: AccountType) => {
        if (userType === 'PATIENT') {
          return of('PATIENT' as AccountType);
        } else if (userType === 'OWNER') {
          return this.getOwner().pipe(
            switchMap((owner: OwnerType | null) => {
              if (owner?.is_set) {
                return of('PATIENT' as AccountType);
              } else {
                return of('OWNER' as AccountType);
              }
            })
          );
        } else {
          throw new Error('Invalid userType');
        }
      })
    );
  }

  setUserType(userType: AccountType) {
    this.userType.next(userType);
    this.saveInLocalStorage("ONBOARDING_USERTYPE", userType)
  }

  setOtherUserTypeToFalse(userType: AccountType) {
    if (userType !== 'OWNER' && userType !== 'PATIENT') {
      throw new Error('Invalid userType provided');
    }
    if (userType === 'OWNER') {
      this.setPatient({ id: null, country_code: '', phone: '', is_set: false });
    } else {
      this.setOwner({ country_code: '', phone: '', email: '', is_set: false });
    }
  }

  getClientReferenceId() {
    return this.clientReferenceId.asObservable().pipe(
      switchMap(clientReferenceId => {
        if (clientReferenceId) {
          return of(clientReferenceId);
        } else {
          const storedClientReferenceId = this.retrieveFromLocalStorage("STRIPE_CLIENT_REFERENCE_ID");
          return of(storedClientReferenceId);
        }
      }) 
    )
  }

  setClientReferenceId(value) {
    this.clientReferenceId.next(value);
    this.saveInLocalStorage("STRIPE_CLIENT_REFERENCE_ID", value)
  }

  getClientSecret() {
    return this.clientSecret.asObservable().pipe(
      switchMap(clientSecret => {
        if (clientSecret) {
          return of(clientSecret);
        } else {
          const storedClientSecret = this.retrieveFromLocalStorage("STRIPE_CLIENT_SECRET");
          return of(storedClientSecret);
        }
      }) 
    )
  }

  synchronousGetClientSecret() {
    return this.retrieveFromLocalStorage("STRIPE_CLIENT_SECRET");
  }

  setClientSecret(value) {
    this.clientSecret.next(value);
    this.saveInLocalStorage("STRIPE_CLIENT_SECRET", value)
  }

  getFormError() {
    return this.formError.asObservable();
  }

  setFormError(formError) {
    this.userType.next(formError);
  }

  saveInLocalStorage(key, value) {
    localStorage.setItem(key, value);
  }

  retrieveFromLocalStorage(key): string {
    return localStorage.getItem(key);
  }

  deleteOnboardingData() {
    Object.keys(localStorage)
      .filter(x =>
        x.startsWith('ONBOARDING_') || x === "account_created")
      .forEach(x => 
        localStorage.removeItem(x));
    this.userType.next(null);
    this.patient.next(null);
    this.owner.next(null);
    this.contacts.next([]);
    this.formError.next(null);
  }

  deleteStripeData() {
    Object.keys(localStorage)
      .filter(x =>
        x.startsWith('STRIPE_'))
      .forEach(x => 
        localStorage.removeItem(x));
    this.clientReferenceId.next("");
    this.clientSecret.next("");
  }
}
