import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatNumbersDirective } from '@app/utils/phone/directives/format-numbers.directive';
import { PrimaryHeaderComponent } from '@app/components/primary-header/primary-header.component';
import { WhiteHeaderComponent } from '@app/components/white-header/white-header.component';
import { ProgressBarComponent } from '@app/components/progress-bar/progress-bar.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    FormatNumbersDirective,
    PrimaryHeaderComponent,
    WhiteHeaderComponent,
    ProgressBarComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    FormatNumbersDirective,
    PrimaryHeaderComponent,
    WhiteHeaderComponent,
    ProgressBarComponent
  ]
})
export class SharedModule { }
