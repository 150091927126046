import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { OnboardingService } from './onboarding.service';

@Injectable({
  providedIn: 'root'
})
export class UserAppLoadNavigationService {

  isAppLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private onboarding: OnboardingService,
  ) {}

  redirectUserOnAppLoad(userId, role_type, params?) {
    let navigationPromise;
    if (params?.onboarding) {
      switch (params?.onboarding) {
        case "login":
          this.onboarding.setUserType(params.userType);
          navigationPromise = this.router.navigate([`/activate/${params.activationCode}/form`]);
          break;
        case "contactsList":
          navigationPromise = this.router.navigate([`${userId}/contacts-list`]);
          break;
        case "addContact":
          navigationPromise = this.router.navigate([`${userId}/contact-add`]);
        default:
          navigationPromise = Promise.resolve();
          break;
      }
    } else {
      if (role_type == "ADMIN") {
        navigationPromise = this.router.navigateByUrl(`${userId}/home-bo`, {replaceUrl: true});
      } else {
        navigationPromise = this.router.navigate([userId + '/user-devices/' + userId]);
      }
    }
    return navigationPromise;
  }
}