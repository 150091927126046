import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

export const isDifferentUser = (userId: number, loggedInUserId: number) => {
    if (userId !== loggedInUserId) {
        return true;
    }
    return false;
};

export const countPendingContacts = (patientContacts) => {
    var count = 0;
    patientContacts.forEach((patientContact) => {
      if (patientContact.invitationState == 'PENDING') {
        count++;
      }
    });
    return count;
  }

export const goBackGeneric = (goBackFunction = null) => {
  if (goBackFunction) {
    goBackFunction();
  } else {
    window.history.back();
  }
}

export const showConfirmationCodePrompt = async (
  alertController: AlertController,
  translateService: TranslateService,
  title: string,
  cancelFunction: () => void, 
  confirmFunction: (code: string) => void
): Promise<void> => {
  const alert = await alertController.create({
    header: translateService.instant("phone_number_verification"),
    message: translateService.instant(`backend.${title}`),
    inputs: [
      {
        name: 'code',
        type: 'text',
        placeholder: translateService.instant("change_phone.code.placeholder"),
        attributes: {
          maxlength: 6,
          inputmode: 'numeric',
        },
      },
    ],
    buttons: [
      {
        text: translateService.instant('cancel'),
        role: 'cancel',
        cssClass: 'secondary',
        handler: cancelFunction
      },
      {
        text: translateService.instant('confirm'),
        handler: confirmFunction
      },
    ],
  });
  await alert.present();
};